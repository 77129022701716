'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as DialogPrimitive from '@radix-ui/react-dialog';
import * as React from 'react';
import { Button } from '../Button';
import { CrossLine } from '@zealy/icons';
import { cn } from '../../utils/cn';
import { drawerContentStyles } from './Dialog.styles';
const Dialog = DialogPrimitive.Root;
const DialogTrigger = DialogPrimitive.Trigger;
const DialogPortal = DialogPrimitive.Portal;
const DialogClose = DialogPrimitive.Close;
const DialogOverlay = React.forwardRef(({ className, ...props }, ref) => (_jsx(DialogPrimitive.Overlay, { ref: ref, className: cn('fixed inset-0 z-overlay bg-blackA3 data-[state=open]:animate-overlayShow data-[state=closed]:animate-overlayHide', className), ...props })));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;
const DialogContent = React.forwardRef(({ className, children, hideClose, ...props }, ref) => (_jsxs(DialogPortal, { children: [_jsx(DialogOverlay, {}), _jsxs(DialogPrimitive.Content, { ref: ref, className: cn('outline-none @container p-200 fixed left-[50%] top-[50%] z-dialog grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-200 border bg-modal shadow-popover duration-200 data-[state=open]:animate-[dialogContentShow_250ms] data-[state=closed]:animate-[dialogContentHide_150ms] rounded-popover', className), ...props, children: [children, !hideClose && (_jsx(DialogPrimitive.Close, { className: "absolute right-100 top-100", asChild: true, children: _jsx(Button, { "aria-label": "Close", leftIcon: _jsx(CrossLine, {}), onlyIcon: true, mutedText: true, variant: "ghost", size: "sm" }) }))] })] })));
DialogContent.displayName = DialogPrimitive.Content.displayName;
const DialogDrawerContent = React.forwardRef(({ className, children, placement = 'left', ...props }, ref) => (_jsx(DialogPortal, { children: _jsx(DialogPrimitive.Content, { ref: ref, className: cn(drawerContentStyles({ placement }), className), ...props, children: children }) })));
DialogContent.displayName = DialogPrimitive.Content.displayName;
const DialogHeader = ({ className, ...props }) => (_jsx("div", { className: cn('flex flex-col space-y-150 text-center @sm:text-left', className), ...props }));
DialogHeader.displayName = 'DialogHeader';
const DialogFooter = ({ className, ...props }) => (_jsx("div", { className: cn('flex flex-col-reverse @sm:flex-row @sm:justify-end gap-100', className), ...props }));
DialogFooter.displayName = 'DialogFooter';
const DialogTitle = React.forwardRef(({ className, ...props }, ref) => (_jsx(DialogPrimitive.Title, { ref: ref, className: cn('body-component-lg-bold text-primary', className), ...props })));
DialogTitle.displayName = DialogPrimitive.Title.displayName;
const DialogDescription = React.forwardRef(({ className, ...props }, ref) => (_jsx(DialogPrimitive.Description, { ref: ref, className: cn('body-paragraph-md text-secondary', className), ...props })));
DialogDescription.displayName = DialogPrimitive.Description.displayName;
export { Dialog, DialogPortal, DialogOverlay, DialogClose, DialogTrigger, DialogContent, DialogDrawerContent, DialogHeader, DialogFooter, DialogTitle, DialogDescription, };
