'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Collapsible from '@radix-ui/react-collapsible';
import React, { forwardRef } from 'react';
import { ChevronRightLine } from '@zealy/icons';
import { cn } from '../../utils/cn';
import { ItemAnatomy, TabItem } from './TabItem';
const Root = forwardRef(({ className, ...props }, ref) => {
    const [open, setOpen] = React.useState(false);
    return (_jsx(Collapsible.Root, { ref: ref, open: open, onOpenChange: setOpen, className: cn('group data-[state=open]:bg-tabs-selected rounded-tabs-md', className), ...props }));
});
const Trigger = forwardRef(({ children, className, ...props }, ref) => (_jsx(Collapsible.Trigger, { className: "w-full outline-none", asChild: true, children: _jsx(TabItem, { ref: ref, className: cn('w-full', className), ...props, children: _jsx(ItemAnatomy.Icon, { color: "neutral", className: "transition-transform duration-100 group-data-[state=open]:rotate-90", size: "md", children: _jsx(ChevronRightLine, {}) }) }) })));
const Content = ({ className, ...props }) => (_jsx(Collapsible.Content, { className: cn('group-data-[state=open]:animate-slideDown group-data-[state=closed]:opacity-0 overflow-hidden flex flex-col gap-y-tabs-list-sm-y group-data-[state=open]:p-tabs-list-y-sm', className), ...props }));
export const TabItemExpandable = forwardRef(({ children, className, collapsibleProps, ...props }, ref) => {
    return (_jsxs(Root, { ...collapsibleProps, children: [_jsx(Trigger, { ...props, ref: ref }), _jsx(Content, { children: children })] }));
});
export const ExpandableItemAnatomy = {
    Root,
    Trigger,
};
