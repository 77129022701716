import { cva } from 'class-variance-authority';
export const drawerContentStyles = cva([
    'fixed z-drawer grid gap-200  bg-secondary outline-none',
    'ease-dialogEase -translate-x-0 -translate-y-0 p-200',
], {
    variants: {
        placement: {
            top: [
                'top-0 left-0 right-0 max-w-full',
                'data-[state=open]:animate-drawerSlideEnterTop data-[state=closed]:animate-drawerSlideExitTop border rounded-b-md sm:rounded-t-none border-t-0',
            ],
            right: [
                'top-auto left-auto right-0 top-0 bottom-0 border rounded-l-md sm:rounded-r-none border-r-0',
                'data-[state=open]:animate-drawerSlideEnterRight data-[state=closed]:animate-drawerSlideExitRight',
            ],
            bottom: [
                'top-auto bottom-0 left-0 right-0 border rounded-t-md sm:rounded-b-none border-b-0 max-w-full',
                'data-[state=open]:animate-drawerSlideEnterBottom data-[state=closed]:animate-drawerSlideExitBottom',
            ],
            left: [
                'left-0 top-0 bottom-0 border rounded-r-md sm:rounded-l-none border-l-0',
                'data-[state=open]:animate-drawerSlideEnterLeft data-[state=closed]:animate-drawerSlideExitLeft',
            ],
        },
    },
    defaultVariants: {
        placement: 'left',
    },
});
